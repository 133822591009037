const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};

const units = {
  xs2: '215px',
  xs: '275px',
  sm: '300px',
  base: '350px',
  xl: '460px',
  xl3: '768px',
};

const colors = {
  primary: {
    lightest: '#F6D0D7',
    light: '#ff5a5a',
    base: '#c12744',
    dark: '#7f2e3f',
  },
  secondary: {
    lightest: '#C7F6FF',
    light: '#2cdbe0',
    base: '#00b4d8',
    medium: '#13759b',
    dark: '#023e8a',
  },
  tertiary: {
    base: '#44274b',
  },
  grey: {
    base: '#e6e6e6',
    dark: '#364b5e',
  },
};

const pristineConfig = {
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  components: {
    button: {
      height: {
        sm: '33px',
      },
    },
    label: {
      height: {
        lg: '80px',
      },
    },
    navigation: {
      height: {
        mobile: '80px',
        desktop: '128px',
      },
      secondaryLinks: {
        width: {
          xl: '198px',
          xl2: '228px',
        },
      },
    },
  },
  gutters: {
    xs2: '7.5px',
    xs: '10px',
    sm: '12.5px',
    base: '16px',
    md: '27.5px',
    lg: '40px',
    xl: '55px',
  },
  colors: {
    ...colors,
  },
  gradients: {
    primary: `${colors.primary.light} 39.25%, ${colors.primary.base} 85.59%, ${colors.primary.dark} 111.12%`,
    secondary: `${colors.secondary.light} 25.77%, ${colors.secondary.base} 58.78%, ${colors.secondary.dark} 100%`,
    mix1: `${colors.primary.base} 3.63%, ${colors.secondary.base} 86.46%`,
    mix2: `${colors.primary.base} 3.63%, rgba(255, 255, 255, 0) 48.95%, ${colors.secondary.base} 86.46%`,
  },
  spacing: {
    xs3: '5px',
    xs2: '8px',
    xs: '10px',
    sm: '15px',
    base: '20px',
    md: '25px',
    lg: '32px',
    xl: '40px',
    xl2: '55px',
    xl3: '75px',
    xl4: '110px',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {},
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    sans: [
      'Raleway',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  fontSize: {
    xs: '12px',
    sm: '14.4px',
    base: '18px',
    md: '22.5px',
    lg: '24px',
    xl: '31.25px',
    xl2: '39.06px',
    xl3: '72px',
  },
  fontWeight: {},
  height: {
    ...units,
  },
  inset: {},
  letterSpacing: {
    negative: '-0.05em',
    base: '0.05em',
    pixel: '0.5px',
  },
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {
    ...units,
  },
  maxWidth: {
    ...units,
  },
  minHeight: {
    ...units,
  },
  minWidth: {
    ...units,
  },
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  transitions: {
    base: 'all 0.2s ease-in-out',
    fast: 'all 0.1s ease-in-out',
  },
  width: {
    ...units,
  },
  zIndex: {},
};

module.exports = pristineConfig;
