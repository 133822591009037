// @ts-ignore
import ready from '@ryanmorr/ready';
import { getUrlParamByName } from '@/utils/app';
import { scrollToSection } from '@/plugins/scrollToSection';

const selector = '#app.quality';

(() => {
  ready(selector, (element: any) => {
    const section = getUrlParamByName('scrollTo');
    if (section) {
      setTimeout(() => {
        scrollToSection(section);
      }, 250);
    }
  });
})();
