// @ts-ignore
import ready from '@ryanmorr/ready';

const selector = '[data-select="true"]';

(() => {
  ready(selector, (element: any) => {
    import('./select')
      .then((Select: any) => {
        const choicesOptions = element.dataset.choicesOptions;
        new Select.default(
          element,
          choicesOptions ? JSON.parse(choicesOptions) : {},
        );
      })
      .then(() => {
        (window as any).emitter.emit('select:loaded');
      });
  });
})();
