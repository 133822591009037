import ready from '@ryanmorr/ready';
import objectFitImages from 'object-fit-images';

const selector = '[data-object-fit-image]';

const ObjectFitImage = (el = null) => {
  objectFitImages(el);
};

(() => {
  ready(selector, (element) => {
    if (element) {
      ObjectFitImage(element);
    }
  });
})();

export default ObjectFitImage;
