import tippy from 'tippy.js';
import 'tippy.js/animations/shift-toward.css';
import { defaultsDeep } from 'lodash';

function popover() {
  return {
    states: [],
    options: {
      animation: 'shift-toward',
      appendTo: () => document.body,
      arrow: false,
      interactive: true,
      offset: [0, 0],
      placement: 'auto',
      trigger: 'click',
    },
    init(options: any) {
      if (!options.disabled) {
        delete options.disabled;
        tippy((this as any).$refs['reference'], {
          ...defaultsDeep(options, this.options),
          content: (this as any).$refs['content'],
        });
      }
    },
  };
}

(window as any).popover = popover;
