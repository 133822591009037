// @ts-ignore
import ready from '@ryanmorr/ready';

const selector = '[data-modal="true"]';

(() => {
  ready(selector, (element: any) => {
    console.log(element);

    import('./modal').then((Modal: any) => {
      element._APP_MODAL = new Modal.default(element);
    });
  });
})();
