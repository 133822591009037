// @ts-ignore
import Iodine from '@kingshott/iodine';

function formItem() {
  return {
    iodine: new Iodine(),
    formEl: null,
    inputEl: null,
    formItem: {},
    errorMessage: '',
    labelVisible: true,
    blurred: true,
    init() {
      this.formEl = (this as any).$refs.formItem.closest('form');
      this.inputEl = (this as any).$refs.formItemInput.children[0];
      this.setErrorMessages();
      this.addEventListeners();
    },
    addEventListeners() {
      const { inputEl, formEl } = this as any;
      if (inputEl) {
        inputEl.addEventListener('input', () => {
          this.labelVisible = !!!inputEl.value;
          this.updateErrorMessage();
        });
        inputEl.addEventListener('focus', () => {
          this.blurred = false;
        });
        inputEl.addEventListener('blur', () => {
          this.blurred = true;
          this.updateErrorMessage();
        });
      }
      if (this.formEl) {
        formEl.addEventListener('submit', () => {
          this.updateErrorMessage();
        });
      }
      this.labelVisible = !!!inputEl.value;
    },
    updateErrorMessage() {
      this.errorMessage = this.getErrorMessage(this.inputEl);
    },
    setErrorMessages() {
      const { iodine } = this;
      const errorMessages = JSON.parse(
        (this as any).$refs.formItem.dataset.errorMessages,
      );
      iodine.setErrorMessages(errorMessages);
    },
    getErrorMessage(input: HTMLInputElement | null) {
      const { iodine } = this;
      if (input) {
        const error: any = iodine.is(
          input.value,
          JSON.parse((this as any).$refs.formItem.dataset.rules),
        );
        if (error !== true && this.blurred) {
          return iodine.getErrorMessage(error);
        }
      }
      return '';
    },
  };
}

(window as any).formItem = formItem;
