import ready from '@ryanmorr/ready';

const selector = '[data-ref="count-up"]';

(() => {
  ready(selector, (element: HTMLElement) => {
    import('./count-up').then((countUp: any) => {
      new countUp.default(element);
    });
  });
})();
