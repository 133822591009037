import { gsap, Power2 } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { getNavigationOffset } from '@/utils/app';

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
  private readonly duration: number;
  private readonly offset: number;

  constructor(duration: number = 0.5, offset: number = 0) {
    this.duration = duration;
    this.offset = offset;
  }

  public scrollTo(target: string | HTMLElement, offset: number = this.offset) {
    gsap.to(window, {
      duration: this.duration,
      ease: Power2.easeInOut,
      scrollTo: {
        y: target,
        offsetY: getNavigationOffset() + offset,
      },
    });
  }
}

(window as any).Scroller = new Scroller();

export default Scroller;
