// @ts-ignore
import ready from '@ryanmorr/ready';

const selector = '[data-sunburst="true"]';

(() => {
  ready(selector, (element: any) => {
    import('./sunburst').then((Sunburst: any) => {
      new Sunburst.default(element);
    });
  });
})();
