// @ts-ignore
import ready from '@ryanmorr/ready';
import { getUrlParamByName } from '@/utils/app';
import { scrollToSection } from '@/plugins/scrollToSection';
import { setSelectValue } from '@/plugins/setSelectValue';

const selector = '#app.contact';

(() => {
  ready(selector, (element: any) => {
    const section = getUrlParamByName('scrollTo');
    const input = getUrlParamByName('selectValue');
    if (input) setSelectValue(input);
    if (section) {
      setTimeout(() => {
        scrollToSection(section);
      }, 250);
    }
  });
})();
