import { debounce } from 'lodash';
import { gsap } from 'gsap';

function cardTile() {
  return {
    width: null,
    height: null,
    scaleValue: 5,
    baseWidth: 109,
    viewBox: 218,
    init() {
      (this as any).updateValues();
      (this as any).setDefaultState(0);
      (this as any).addEventListeners();
    },
    addEventListeners() {
      window.addEventListener(
        'resize',
        debounce(this.updateValues.bind(this), 100),
      );
    },
    setDefaultState(duration: number = 0.2) {
      const ratio = (this as any).width / (this as any).height;
      const scale = this.viewBox / 100 / ratio;
      const x = (this.viewBox - this.baseWidth) / 2;
      gsap.to((this as any).$refs.maskShape, {
        duration: duration,
        x: x,
        scale: this.scaleValue * scale,
        rotation: 180,
        transformOrigin: '50% 50%',
      });
      gsap.to((this as any).$refs.maskImage, {
        duration: duration,
        scale: 1,
        transformOrigin: '50% 50%',
      });
    },
    setActiveState(duration: number = 0.2) {
      const ratio = (this as any).width / (this as any).height;
      const zoomScale = 1.2;
      const scale = this.viewBox / 100 / ratio / zoomScale;
      const x = (this.viewBox - this.baseWidth) / 2;
      gsap.to((this as any).$refs.maskShape, {
        duration: duration,
        x: x,
        scale: scale,
        rotation: 0,
        transformOrigin: '50% 50%',
      });
      gsap.to((this as any).$refs.maskImage, {
        duration: duration,
        scale: zoomScale,
        transformOrigin: '50% 50%',
      });
    },
    getDimensions() {
      return (this as any).$el.getBoundingClientRect();
    },
    updateValues() {
      (this as any).width = this.getDimensions().width;
      (this as any).height = this.getDimensions().height;
    },
  };
}

(window as any).cardTile = cardTile;
