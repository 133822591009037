// @ts-ignore
import ready from '@ryanmorr/ready';

const selector = '[data-slider="true"]';

(() => {
  ready(selector, (element: any) => {
    import('./slider').then((Slider: any) => {
      const swiperOptions = element.dataset.swiperOptions;
      new Slider.default(
        element,
        swiperOptions ? JSON.parse(swiperOptions) : {},
      );
    });
  });
})();
