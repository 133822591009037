// @ts-ignore
import ready from '@ryanmorr/ready';

const selector = '[data-slider-spotlight="true"]';

(() => {
  ready(selector, (element: any) => {
    import('./sliderSpotlight').then((SliderSpotlight: any) => {
      const swiperOptions = element.dataset.swiperOptions;
      new SliderSpotlight.default(
        element,
        swiperOptions ? JSON.parse(swiperOptions) : {},
      );
    });
  });
})();
