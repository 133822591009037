export const getNavigationOffset = () => {
  return ['.navigation']
    .map((selector) => {
      const el: HTMLElement | null = document.querySelector(selector);
      return el ? el.offsetHeight : 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue);
};

export const getUrlParamByName = (
  name: string,
  url: string = window.location.href,
) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const textSplitter = (text: string, limit: number) => {
  const lines = [];
  while (text.length > limit) {
    const chunk = text.substring(0, limit);
    const lastWhiteSpace = chunk.lastIndexOf(' ');
    if (lastWhiteSpace !== -1) {
      lines.push(chunk.substring(0, lastWhiteSpace));
      text = text.substring(lastWhiteSpace + 1);
    } else {
      lines.push(chunk);
      text = text.substring(limit);
    }
  }
  lines.push(text);
  return lines;
};

export const isIE = () => {
  return !!(window as any).document.documentMode;
};
